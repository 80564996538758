<template>
  <div>
    <Breadcrumbs main="" title="Events" />
    <div class="container-fluid">
      <div class="row p-3">
          <div class="col-md-1">
            Filter by User :
          </div>
          <div class="col-md-4">
            <b-form-select name="type" size="sm" v-model="selectedUser" :options="users_opt" @change="getEvent()" required></b-form-select>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12">
              <h4 class="text-info">
                Latest Events For All Monitors (up, down, start, pause)
              </h4>
              <b-row class="mt-3">
                <b-col class="col-md-6 col-12">
                  <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                  </b-input-group>
                </b-col>

                <b-col class="text-right col-md-6 col-12">
                  <b-form-group
                    label-cols="10"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getEvent()"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div>
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="event_data"
                  :filter="filter"
                  @filtered="onFiltered"
                  :fields="tablefields"
                >
                  <template #table-caption>Ongoing Events.</template>
                  <template #cell(event)="data">
                    <span
                      v-if="data.item.event == 'start'"
                      class="badge badge-flat badge-primary"
                    >
                    Started</span
                    >
                    <span
                      v-if="data.item.event == 'pause'"
                      class="badge badge-flat badge-dark text-white"
                    >
                    Paused </span
                    >
                    <span
                      v-if="data.item.event == 'down'"
                      class="badge badge-flat badge-danger"
                    >
                    Down </span
                    >
                    <span
                      v-if="data.item.event == 'up'"
                      class="badge badge-flat badge-success"
                    >
                    UP </span
                    >
                  </template>
                  <template #cell(createdAt)="data">
                    <span class="font-weight-normal">{{changeDateFormate(data.item.createdAt)}}</span>
                  </template>
                </b-table>
              </div>
              <b-col md="6" class="mt-3 p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getEvent('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="event_data.length >= 1" @click="getEvent('next')">Next &raquo;</a>
                </div>
              </b-col>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import moment from 'moment'
import ManagerController from "../../../services/managerController";
import UserController from "../../../services/userController";
export default {
  name: "Event",
  props:{monitor_id:String},
  data() {
    return {
      event_data: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50],
      loading: false,
      tablefields: [
        { key: "mName", label: "Name", sortable: true },
        { key: "event", label: "Event", sortable: true },
        { key: "createdAt", label: "Started At", sortable: true },
      ],
      users:{},
      selectedUser:null,
      users_opt:[{ value: null, text:'All Events' }]
    };
  },
  mounted() {
    this.getEvent();
    this.getUsers();
  },
  watch:{
    monitor_id(){
      this.currentPage = 1
      this.getEvent()
    }
  },
  methods: {
    changeDateFormate(d){
        return moment(d).format('D/M/YYYY, HH:mm A')
    },
    async getEvent(txt) {
      if(txt == 'next'){this.currentPage ++}
          if(txt == 'prev'){
            if(this.currentPage != 1){
            this.currentPage --
            }
          }
      var payload =''
      if(this.selectedUser != null){
        payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage+'&userid='+this.selectedUser
      }
      else{
        payload = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
      }
      this.loading = true;
      let response = await ManagerController.getEvents(payload);
      if (response.result) {
        this.event_data = []
        this.event_data = response.data;
        this.totalRows = this.event_data.length;
      }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getUsers(){
      let response = await UserController.getAllUsers()
      if (response.result) {
          this.users = response.message.filter(t=>{
            if(t.role != 'manager'){
                  return true;
              }
          });
          this.users.map((p)=> {
            this.users_opt.push({'value':p._id,'text':p.username+' ('+p.email+')'})
          })
      }
      else{
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
      }
    },
  },
};
</script>




<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}
</style>